import { defineMessages } from 'umi/locale';
import globalMessages from '@/messages';
import patientMessages from '@/messages/patient';
import formMessages from '@/messages/form';
import { NAMESPACE } from './constants';

const messages = defineMessages({
  fieldCreated: {
    id: `${NAMESPACE}.fieldCreated`,
    defaultMessage: 'Field created successfully!',
  },
  fieldUpdated: {
    id: `${NAMESPACE}.fieldUpdated`,
    defaultMessage: 'Field updated successfully!',
  },
});

export default {
  ...formMessages,
  ...patientMessages,
  ...messages,
  update: globalMessages.common.update,
  saveAndNew: globalMessages.common.saveAndNew,
  save: globalMessages.common.save,
  cancel: globalMessages.common.cancel,
};
