/* eslint-disable react/jsx-indent */
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { formatMessage } from 'umi/locale';

import { PP_FORM_INPUT, PP_FORM_SELECT, SUBMIT_BUTTON } from '@/constants';
import { Modal, Button, Form, Row, Col, Input, Select } from '@/componentsX/UIElements';

import { FIELD_STATUS, FIELD_TYPES, FORM } from './constants';
import { mapToFormData } from './service';
import messages from './messages';
import Styles from './styles.less';

const { Item: FormItem, createFormField } = Form;
const { Option } = Select;

@Form.create({
  mapPropsToFields(props) {
    const field = mapToFormData(props.field);
    return Object.keys(field).reduce((acc, fieldName) => {
      acc[fieldName] = createFormField({ value: field[fieldName] });
      return acc;
    }, {});
  },
})
class PatientCheckinFieldModalView extends PureComponent {
  static propTypes = {
    isOpen: propTypes.bool.isRequired,
    isEdit: propTypes.bool.isRequired,
    loading: propTypes.bool.isRequired,
    submitButton: propTypes.string.isRequired,
    handleSave: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    handleUpdate: propTypes.func.isRequired,
  };

  state = {
    selectedFieldType: '',
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (state.selectedFieldType === '') {
      return {
        selectedFieldType: nextProps.field.fieldType || '',
      };
    }
    return null;
  }

  handleFieldTypeChange = value => {
    this.setState({ selectedFieldType: value });
  };

  handleCallback = (callBack, values) => {
    const { form } = this.props;
    callBack(form, values);
  };

  handleCancel = () => {
    this.setState({ selectedFieldType: '' });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.handleCancel();
  };

  renderFooter = () => {
    const {
      isEdit,
      disableButtonUpdate,
      handleSave,
      handleUpdate,
      loading,
      submitButton,
    } = this.props;

    const handleSubmit = values => this.handleCallback(handleSave, values);
    const handleSubmitUpdate = values => this.handleCallback(handleUpdate, values);
    const submitButtons = isEdit
      ? [
          <Button
            key="submit"
            type="primary"
            loading={loading && submitButton === SUBMIT_BUTTON.update}
            disabled={disableButtonUpdate}
            onClick={handleSubmitUpdate}
          >
            {formatMessage(messages.update)}
          </Button>,
        ]
      : [
          <Button
            key="submit"
            type="primary"
            loading={loading && submitButton === SUBMIT_BUTTON.save}
            onClick={handleSubmit}
          >
            {formatMessage(messages.save)}
          </Button>,
        ];
    return [
      <Button key="cancel" onClick={this.handleCancel}>
        {formatMessage(messages.cancel)}
      </Button>,
      ...submitButtons,
    ];
  };

  render() {
    const { form, isOpen, isEdit } = this.props;
    const { getFieldDecorator } = form;
    const { selectedFieldType } = this.state;
    return (
      <Modal
        title={isEdit ? 'Update Field' : 'New Field'}
        centered
        visible={isOpen}
        destroyOnClose
        maskClosable={false}
        footer={this.renderFooter()}
        onCancel={this.handleCancel}
        width="600px"
        className={Styles.modalBody}
      >
        <Form layout="vertical" className={Styles.resource}>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <FormItem label="Field Name" colon={false}>
                {getFieldDecorator(FORM.fieldName, {
                  rules: [{ required: true, message: 'Field Name is required' }],
                })(<Input className={PP_FORM_INPUT} placeholder="Field Name" />)}
              </FormItem>
            </Col>
            <Col sm={24} md={12}>
              <FormItem label="Field Type" colon={false}>
                {getFieldDecorator(FORM.fieldType, {
                  rules: [{ required: true, message: 'Field Type is required' }],
                })(
                  <Select
                    className={PP_FORM_SELECT}
                    placeholder="Select type"
                    onChange={this.handleFieldTypeChange}
                  >
                    {FIELD_TYPES.map(type => (
                      <Option key={type.name} value={type.name}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            {selectedFieldType === 'SELECT' ? (
              <Col sm={24} md={24}>
                <FormItem label="Field Options" colon={false} help="Options separated by comma">
                  {getFieldDecorator(FORM.fieldOptions, {
                    rules: [
                      { required: true, message: 'Field Options is required for SELECT field' },
                    ],
                  })(
                    <Input
                      className={PP_FORM_INPUT}
                      placeholder="Options is required for SELECT field"
                    />
                  )}
                </FormItem>
              </Col>
            ) : null}
            {isEdit ? (
              <Col sm={24} md={12}>
                <FormItem label="Field Status" colon={false}>
                  {getFieldDecorator(FORM.status, {
                    rules: [{ required: true, message: 'Field status is required' }],
                  })(
                    <Select className={PP_FORM_SELECT} placeholder="Select status">
                      {FIELD_STATUS.map(type => (
                        <Option key={type.name} value={type.name}>
                          {type.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </Col>
            ) : null}
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default PatientCheckinFieldModalView;
