import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'appointment-calendar', ...(require('/app/src/models/appointment-calendar.js').default) });
app.model({ namespace: 'appointment-status', ...(require('/app/src/models/filter/appointment-status.js').default) });
app.model({ namespace: 'dentist', ...(require('/app/src/models/filter/dentist.js').default) });
app.model({ namespace: 'major', ...(require('/app/src/models/filter/major.js').default) });
app.model({ namespace: 'patient', ...(require('/app/src/models/filter/patient.js').default) });
app.model({ namespace: 'schedulerType', ...(require('/app/src/models/filter/schedulerType.js').default) });
app.model({ namespace: 'service', ...(require('/app/src/models/filter/service.js').default) });
app.model({ namespace: 'staff', ...(require('/app/src/models/filter/staff.js').default) });
app.model({ namespace: 'global', ...(require('/app/src/models/global.js').default) });
app.model({ namespace: 'menu', ...(require('/app/src/models/menu.js').default) });
app.model({ namespace: 'appointment', ...(require('/app/src/models/modal/appointment.js').default) });
app.model({ namespace: 'dentist', ...(require('/app/src/models/modal/dentist.js').default) });
app.model({ namespace: 'emailTemplate', ...(require('/app/src/models/modal/emailTemplate.js').default) });
app.model({ namespace: 'freeService', ...(require('/app/src/models/modal/freeService.js').default) });
app.model({ namespace: 'major', ...(require('/app/src/models/modal/major.js').default) });
app.model({ namespace: 'patient', ...(require('/app/src/models/modal/patient.js').default) });
app.model({ namespace: 'patientCheckinFieldModal', ...(require('/app/src/models/modal/patientCheckinFieldModal.js').default) });
app.model({ namespace: 'policyModal', ...(require('/app/src/models/modal/policyModal.js').default) });
app.model({ namespace: 'propertyModal', ...(require('/app/src/models/modal/propertyModal.js').default) });
app.model({ namespace: 'resetPassword', ...(require('/app/src/models/modal/resetPassword.js').default) });
app.model({ namespace: 'role', ...(require('/app/src/models/modal/role.js').default) });
app.model({ namespace: 'schedulerType', ...(require('/app/src/models/modal/schedulerType.js').default) });
app.model({ namespace: 'sendOut', ...(require('/app/src/models/modal/sendOut.js').default) });
app.model({ namespace: 'service', ...(require('/app/src/models/modal/service.js').default) });
app.model({ namespace: 'user', ...(require('/app/src/models/modal/user.js').default) });
app.model({ namespace: 'notification', ...(require('/app/src/models/notification.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
