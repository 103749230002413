import request from '@/utils/request';
import { FORM } from './constants';
import { trimString } from '@/utils/utils';

export function mapToPostData(object) {
  const data = {};
  Object.keys(object).forEach(key => {
    const value = trimString(object[key]);
    if (key === FORM.fieldOptions) {
      let arr;
      if (Array.isArray(value)) {
        arr = value;
      } else {
        arr = value.split(',').map(v => trimString(v));
      }
      data[key] = JSON.stringify(arr);
    } else {
      data[key] = value;
    }
  });
  return data;
}

export async function createField(field) {
  const postData = mapToPostData(field);
  return request('/patients/check-in/form-config', {
    method: 'POST',
    body: postData,
  });
}

export async function updateField(id, field) {
  const putData = mapToPostData(field);
  return request(`/patients/check-in/form-config/${id}`, {
    method: 'PUT',
    body: putData,
  });
}

export function mapToFormData(field = {}) {
  const f = {};
  Object.keys(FORM).forEach(key => {
    const property = FORM[key];
    const value = field[key] || field[property];
    if (key === FORM.fieldOptions && value) {
      const arr = JSON.parse(value);
      f[property] = arr.join(', ');
    }
    f[property] = value;
  });
  return f;
}
